<div class="container">
    <ng-container *ngIf="isLoginSection">
        <div class="col-xs-12">
            <h2 class="">Log - in</h2>
            <h5>Enter your details to log in to your account. Sign-up <a [routerLink]="['/sign-up']" routerLinkActive="router-link-active" >here</a></h5>
        </div>
        <form [formGroup]="loginForm">
            <div class="row">
                <div class="col-xs-11">
                    <div class="d-flex justify-content-center">
                        <label>Email *</label>
                        <input formControlName="email" type="email" class="form-control max-width-250" id="email"
                            aria-describedby="emailHelp"
                            [ngClass]="{ 'text-danger': submitted && fLogin['email'].errors }"
                            placeholder="Enter your email">
                        <div class="error-msg" *ngIf="loginForm.get('email')?.invalid && submitted">
                            <span> Email is required</span>
                        </div>
                    </div>
                </div>
                <!--Error-->
                <div class="col-xs-11 mt-4">
                    <div *ngIf="fLogin['email'].touched && fLogin['email'].invalid" class="alert alert-danger">
                        <div *ngIf="fLogin['email'].errors && fLogin['email'].errors['required']">email is required.
                        </div>
                        <div *ngIf="fLogin['email'].errors && fLogin['email'].errors['email']">You did not supply a
                            valid
                            email
                            address.</div>
                    </div>
                </div>
                <div class="col-xs-11">
                    <div class="d-flex justify-content-center">
                        <label>Password *</label>
                        <input formControlName="password" type="password" class="form-control max-width-250"
                            id="password" aria-describedby="emailHelp"
                            [ngClass]="{ 'text-danger': submitted && fLogin['password'].errors }"
                            placeholder="Enter your Password">
                        <div class="error-msg" *ngIf="loginForm.get('password')?.invalid && submitted">
                            <span> Valid password is required</span>
                        </div>
                    </div>
                </div>
                <div class="col-xs-11 mt-4">
                    <div *ngIf=" fLogin['password'].invalid" class="alert alert-danger">
                        <div *ngIf="fLogin['password'].errors && fLogin['password'].errors['required']">password is
                            required.</div>
                    </div>
                </div>
                <div class="col-xs-11">
                    <p>Forgot password? <a [routerLink]="['/forgot']">Click here to reset</a></p>
                </div>
                <div class="col-xs-12">
                    <button type="button" class="btn" (click)="login()">
                        Continue
                    </button>
                    <div class="error-msg" *ngIf="error && submitted">
                        <span> {{error}}</span>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="!isLoginSection">
        <div class="row">
            <div class="col-xs-12">
                <h2 class="text-danger col-xs-6">Success</h2>
            </div>
            <div class="col-xs-12 d-flex succ-text">
                We sent you a link to your email to login to your dashboard
            </div>
        </div>
    </ng-container>
</div>